import { $params as $routesParmas, $path as $routesPath } from 'remix-routes'

import { PaginationSchema } from '~/utils/schemas'

// Used for easy code inference and importing
export const $path = $routesPath
export const $params = $routesParmas

export const getPaginationParams = (request: Request, id = '') => {
  const query = new URL(request.url).searchParams

  const prefix = id ? `${id}_` : ''

  const page = query.get(`${prefix}page`) ?? undefined
  const perPage = query.get(`${prefix}perPage`) ?? undefined
  const order = query.get(`${prefix}order`) ?? undefined
  const orderBy = query.get(`${prefix}orderBy`) ?? undefined

  return PaginationSchema.parse({
    page,
    perPage,
    order,
    orderBy
  })
}
